{
  "BUTTON_YES": "Ja",
  "BUTTON_NO": "Nee",
  "BUTTON_OK": "OK",
  "BUTTON_CANCEL": "Annuleren",
  "BUTTON_SAVE": "Opslaan",
  "BUTTON_SEND": "Versturen",
  "SNACKBAR": {
    "CHAT_RETRIEVE_ERROR": "Er ging iets mis bij het laden van de chat. Probeer het later opnieuw.",
    "CHAT_HAS_JUST_BEEN_ASSIGNED": "De chat is zojuist toegewezen aan een andere gebruiker."
  },
  "TOOLTIPS": {
    "LOGOUT": "Uitloggen",
    "ARCHIVE_CHAT": "Chat archiveren",
    "CHAT_INFORMATION": "Informatie",
    "EXPORT_CHAT_AS_PDF": "Chat exporteren als PDF",
    "EXPORT_INSIGHT_AS_PDF": "Insights exporteren als PDF"
  },
  "LOGIN": {
    "TITLE": "Login met je account",
    "EMAIL": "E-mailadres",
    "PASSWORD": "Wachtwoord",
    "REMEMBER": "Onthoud mij",
    "ERROR": {
      "INACTIVITY": "U bent uitgelogd wegens inactiviteit.",
      "MISMATCH": "U bent uitgelogd wegens inactiviteit.",
      "CREDENTIALSNOTFOUND": "De ingevoerde gegevens zijn onjuist.",
      "UNKNOWN": "Er is een onbekende fout opgetreden. Probeer het later opnieuw.",
      "2FA_INVALID": "De ingevoerde code is onjuist.",
      "NO2FA": "Uw account is niet geconfigureerd voor tweestapsverificatie."
    }
  },
  "WEBCHAT": {
    "WELCOME_BACK": "Welkom terug bij onze chat",
    "WELCOME": "Welkom bij onze chat",
    "EMERGENCY": "In geval van nood, bel 112 of neem contact op met ons counselingcentrum.",
    "CHOOSE_USERNAME": "Kies een gebruikersnaam",
    "FILL_IN_PIN": "Vul uw pincode in",
    "FORGOT_PIN": "Bent u uw pincode vergeten? Start een nieuw gesprek.",
    "I_ACCEPT_THE_TERMS": "Ik accepteer de",
    "TERMS": "algemene voorwaarden",
    "CHOOSE_PIN": "Kies een pincode om uw chat te beveiligen",
    "OUR_TEAM": "Onze counselors",
    "OUR_TEAM_TEXT": "Stuur ons een bericht zodat we u kunnen helpen. ",
    "COUNSELOR": "Counselor",
    "BUTTON_LOGIN": "Inloggen",
    "LOGOUT": "Chat vergrendelen",
    "LOADING": "Laden...",
    "REFLECTION": "Reflectie",
    "REFLECTION_SPARE_TIME": "Vrije tijd",
    "REFLECTION_WORK": "Werk",
    "REFLECTION_FEELINGS": "Emoties",
    "REFLECTION_MOODS": "Stemming",
    "SEND": "Versturen",
    "ANSWER": "Antwoorden",
    "ESTABLISHING_SECURE_CONNECTION": "Beveiligde verbinding tot stand brengen",
    "UNWANTED_BEHAVIOUR": "Ongewenst gedrag",
    "FORGOT_PIN_TITLE": "Pincode vergeten?",
    "FORGOT_PIN_TEXT": "Je bent je pincode vergeten. Zonder deze pincode kun je niet meer bij je vorige chat. Je kunt een nieuwe chat starten. Wil je dat doen?"
  },
  "STATUS": {
    "AVAILABLE": "Beschikbaar",
    "UNAVAILABLE": "Niet beschikbaar"
  },
  "CHAT": {
    "WELCOME": "Welkom",
    "PENDING": "In de wacht..",
    "NEW_CHATS": "Nieuwe chats",
    "SHOW_ARCHIVE": "Toon archief",
    "ARCHIVE": "Archief",
    "COUNSELORS": "Online",
    "COUNSELORS_IN_THIS_CHAT": "Vertrouwenspersonen in deze chat",
    "NONE": "Geen",
    "CHAT_INFORMATION": "",
    "ENABLE_PUSH_NOTIFICATIONS": "Push notificaties inschakelen",
    "NO_CHATS": "Geen chats",
    "NO_NAME": "Geen naam",
    "MY_CHATS": "Mijn chats",
    "RECONNECTING": "Verbinding herstellen...",
    "RECONNECTED": "Verbinding hersteld",
    "TYPE_MESSAGE": "Typ een bericht...",
    "SAVE": "Opslaan",
    "ENCRYPTION": {
      "CHANGE_ENCRYPTION_KEY": "Verander encryptiesleutel",
      "ENCRYPTION_KEY": "Encryptiesleutel",
      "ENCRYPTION_KEY_REPEAT": "Herhaal encryptiesleutel"
    },
    "MODAL_ARCHIVE": {
      "TITLE": "Chat archiveren?",
      "TEXT": "Weet u zeker dat u deze chat wilt archiveren?"
    },
    "MODAL_CHANGE_STATUS": {
      "TITLE": "Actieve chats",
      "TEXT": "U heeft nog actieve chats. Wilt u toch doorgaan?"
    },
    "USER_FORGOT_PIN": "Let op: de gebruiker heeft aangegeven dat hij/zij zijn/haar pincode van de app is vergeten. De gebruiker kan niet meer bij de chat en komt mogelijk onder een nieuwe naam binnen.",
    "USER_LOGGED_OUT": "De gebruiker is zelf uitgelogd en kan daarom geen berichten meer ontvangen of versturen."
  },
  "CHAT_INFORMATION": {
    "TITLE": "Chatinformatie",
    "COMPANY_CODE": "Code",
    "EXTERNAL_ID": "Abonneenummer",
    "COMPANY_DEPARTMENT": "Organisatiedeel",
    "COMPANY_NAME": "Organisatienaam",
    "USER_INFO": "App-gebruiker",
    "GENDER": "Gender",
    "AGE_GROUP": "Leeftijdsgroep"
  },
  "AUTOMATIC_RESPONSE": {
    "TITLE": "Openingsbericht",
    "SCHEDULED_TITLE": "Automatisch bericht bij openen chat",
    "GERMAN": "Duits",
    "ENGLISH": "Engels",
    "DURING_WORKING_HOURS_TITLE": "Openingsbericht buiten werktijden",
    "DURING_WORKING_DAYS": "Op werkdagen",
    "DURING_WEEKEND": "In het weekend",
    "DEFINE_WORKING_HOURS": "Werkuren defini\u00ebren",
    "TIME_FROM": "Van",
    "TIME_TO": "Tot"
  },
  "LANGUAGE_NL": "Nederlands",
  "LANGUAGE_EN": "Engels",
  "LANGUAGE_DE": "Duits",
  "LANGUAGE_FR": "Frans",
  "LANGUAGE_IT": "Italiaans",
  "LANGUAGE_ES": "Spaans",
  "UNKNOWN": "Onbekend",
  "INSIGHTS": {
    "CHOOSE_COMPANY": "Kies een bedrijf",
    "ALL_COMPANIES": "Alle bedrijven",
    "CHOOSE_DEPARTMENT": "Kies een afdeling",
    "ALL_DEPARTMENTS": "Alle afdelingen",
    "DOWNLOAD_SCREENS_STATISTICS_CSV": "Download schermstatistieken (.CSV)",
    "DOWNLOAD_ARTICLE_STATISTICS_CSV": "Download artikelstatistieken (.CSV)",
    "DOWNLOAD_USER_STATISTICS_XLSX": "Download gebruikersstatistieken (.XLSX)",
    "GROUP_BY": "Groeperen op",
    "GROUP_BY_VALUES": {
      "PER_DAY": "Per dag",
      "PER_MONTH": "Per maand",
      "PER_YEAR": "Per jaar"
    },
    "CHOOSE_DATE_RANGE": "tijdsspanne opgeven (optioneel)",
    "HEADERS": {
      "AGE": "Leeftijd",
      "SEX": "Gender",
      "LANGUAGE": "Taal",
      "LOGINS": "Logins",
      "SCREEN_VIEWS": "Schermweergaven",
      "USER_ACTIVES": "Actieve gebruikers"
    },
    "TABLE_HEADERS": {
      "DATE_SELECTION": "Datumselectie",
      "COMPANY_NAME": "Bedrijfsnaam",
      "DEPARTMENT_NAME": "Afdelingsnaam",
      "SCREEN_NAME": "Schermnaam",
      "ARTICLE_ID": "Artikel-ID",
      "NUM": "Aantal",
      "CODE": "Code",
      "TITLE_EN": "Titel (EN)",
      "TITLE_DE": "Titel (DE)",
      "TITLE_NL": "Titel (NL)"
    },
    "USER_STATISTICS": "Gebruikersstatistieken",
    "SCREEN_STATISTICS": "Schermstatistieken",
    "USAGE_STATISTICS": "Gebruiksstatistieken",
    "ARTICLE_STATISTICS": "Artikelstatistieken",
    "NO_STATISTICS_WITH_TYPE": "Geen gegevens beschikbaar voor de {{TYPE}} met de huidige selectie."
  },
  "CHANGE_PASSWORD": {
    "TITLE": "Wachtwoord wijzigen",
    "CURRENT_PASSWORD": "Huidig wachtwoord",
    "NEW_PASSWORD": "Nieuw wachtwoord",
    "CONFIRM_PASSWORD": "Bevestig nieuw wachtwoord",
    "WARNING_PGP": "Let op: als u uw wachtwoord wijzigt, genereren we een nieuwe PGP-sleutel, waardoor u geen toegang meer heeft tot oudere berichten.",
    "ERROR_MIN_LENGTH": "Wachtwoord moet minimaal 8 tekens lang zijn",
    "ERROR_LOWERCASE": "Wachtwoord moet minimaal één kleine letter bevatten",
    "ERROR_UPPERCASE": "Wachtwoord moet minimaal één hoofdletter bevatten",
    "ERROR_DIGIT": "Wachtwoord moet minimaal één cijfer bevatten",
    "ERROR_SPECIAL_CHAR": "Wachtwoord moet minimaal één speciaal teken bevatten (!@#$%^&*)",
    "PASSWORD_REQUIREMENTS": "Wachtwoordvereisten",
    "PASSWORD_MISMATCH": "Wachtwoorden komen niet overeen",
    "PASSWORD_CHANGED": "Wachtwoord succesvol gewijzigd",
    "PASSWORD_INCORRECT": "Huidig wachtwoord is onjuist, het wachtwoord is niet gewijzigd"
  },
  "SETTINGS": {
    "CHANGE_PASSWORD": "Wachtwoord wijzigen"
  },
  "ARCHIVE": {
    "NO_ITEMS": "Er zijn nog geen chats gearchiveerd."
  },
  "CHAT_THREAD_STATUS": {
    "FORGOT_PIN": "Pin vergeten",
    "FORGOT_PIN_INFO": "De gebruiker heeft aangegeven dat hij/zij zijn/haar pincode is vergeten. De gebruiker kan niet meer bij de chat en komt mogelijk onder een nieuwe naam binnen.",
    "LOGGED_OUT": "Uitgelogd",
    "LOGGED_OUT_INFO": "De gebruiker is zelf uitgelogd en kan niet meer bij de chat.",
    "WEBCHAT": "Webchat",
    "UNWANTED_BEHAVIOUR": "Ongewenst gedrag",
    "UNWANTED_BEHAVIOUR_INFO": "De gebruiker heeft aangegeven dat er sprake is van ongewenst gedrag."
  }
}
